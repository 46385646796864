import React, { useState, useContext } from "react";
import useFindUser from "components/customHooks/useFindUser";

const UserContext = React.createContext();
export const useUserContext = () => useContext(UserContext);

export default function UserContextProvider({ children }) {
    const { signature, setSignature } = useFindUser();
    return (
        <UserContext.Provider value={{ signature, setSignature }}>
            {children}
        </UserContext.Provider>
    );
}
