import FormikInput from "../FormikInput";
import * as React from "react";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { SESSION_FORM_KEY } from "../../pages/Dashboard";
import { UserRequest } from "../../pages/models/UserRequest";

const StepDetails = (props: { existingRequest?: UserRequest | null }) => {
  const formik = useFormikContext();
  useEffect(() => {
    // Restore form state in case of refresh.
    const existingFormString = window.sessionStorage.getItem(SESSION_FORM_KEY);
    if (!existingFormString) {
      return;
    }
    const existingFormData = JSON.parse(existingFormString);
    formik.setFieldValue("firstName", existingFormData?.firstName ?? "", true);
    formik.setFieldValue("lastName", existingFormData?.lastName ?? "", true);
    formik.setFieldValue(
      "physicalAddress",
      existingFormData?.physicalAddress ?? "",
      true
    );
    formik.setFieldValue("cnp", existingFormData?.cnp ?? "", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikInput name="firstName" label="Prenume" placeholder="Ion" />
      <FormikInput
        name="lastName"
        label="Nume de familie"
        placeholder="Popescu"
      />
      <FormikInput name="cnp" label="CNP" placeholder="1234567890123" />
      <FormikInput
        name="physicalAddress"
        label="Adresă de domiciliu"
        placeholder="str. Bradului nr. 1, sc. A, ap. 15, sector 3, București"
      />
    </>
  );
};

export default StepDetails;
