import React, { useEffect } from "react";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { encodeToBase64 } from "@multiversx/sdk-dapp/utils";
import { useLocation, useSearchParams } from "react-router-dom";
import routes, { routeNames } from "routes";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useUserContext } from "components/contexts/UserContext";
import { AuthenticatedRoutesWrapper } from "@multiversx/sdk-dapp/wrappers";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search, pathname } = useLocation();
  const accInfo = useGetAccountInfo();
  const login = useGetLoginInfo();
  const { signature, setSignature } = useUserContext();
  // Add wallet address to every request

  React.useEffect(() => {
    const signature = searchParams.get("signature");
    if (signature) {
      setSignature(signature);
      localStorage.setItem(
        "token",
        encodeToBase64(
          JSON.stringify({
            address: accInfo.address,
            signature: signature,
          })
        )
      );
    }
  }, [accInfo, searchParams, setSignature]);

  useEffect(() => {
    let signatureToSend = "";
    if (login.tokenLogin?.signature) {
      signatureToSend = login.tokenLogin?.signature;
    } else {
      signatureToSend = signature;
    }

    if (signatureToSend) {
      localStorage.setItem(
        "token",
        encodeToBase64(
          JSON.stringify({
            address: accInfo.address,
            signature: signatureToSend,
          })
        )
      );
    }
  }, [accInfo, signature, login]);

  return (
    <div className="bg-light d-flex flex-column flex-fill wrapper">
      {pathname !== routeNames.unlock && <Navbar />}
      <main className="d-flex flex-column flex-grow-1">
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      {pathname !== routeNames.unlock && <Footer />}
    </div>
  );
};

export default Layout;
