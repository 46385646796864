import * as React from "react";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";

const Home = () => {
  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.dashboard;
    }
  }, [isLoggedIn]);

  return (
    <Container
      fluid
      className="d-flex flex-fill align-items-center home-background"
    >
      <Row className="w-100">
        <Col lg={6} className="px-4  d-flex flex-fill align-items-center">
          <div className="margin-home-column-left">
            <h2 className="mb-3" data-testid="title">
              Ai deja un wallet MultiversX. Perfect. <br />
              Autentifica-te si revendica in mod gratuit si transparent eLEU.
            </h2>

            <Link
              to={routeNames.unlock}
              className="btn btn-primary btn-large light-bg my-4 text-gold float-right"
              data-testid="loginBtn"
            >
              Autentificare
            </Link>

            <h2 className="home-description">
              Nu ai un wallet MultiversX. Nu-i nimic. <br />
              Dureaza cateva minute pentru a genera unul.
            </h2>
            <p className="home-text">
              Esti incepator? Iti recomandam varianta xPortal. <br />
              Detalii aici:{" "}
              <a target="_blank" rel="noreferrer" href="https://xportal.com/">
                https://xportal.com/
              </a>
            </p>
            <p className="home-text">
              Ai mai folosit platforme blockchain. Poti folosi un MultiversX
              DeFi wallet. <br />
              <a
                href="https://chrome.google.com/webstore/detail/xportal-defi-wallet/dngmlblcodfobpdpecaadgfbcggfjfnm"
                target="_blank"
                rel="noreferrer"
              >
                Detalii aici.
              </a>
            </p>
            <p className="home-text">
              Avansat? Poti utiliza un dispozitiv Ledger.
            </p>
          </div>
        </Col>
        <Col lg={6} className="px-4 d-flex flex-fill align-items-center"></Col>
      </Row>
    </Container>
  );
};

export default Home;
