import React from "react";
import { ReactComponent as HeartIcon } from "../../../assets/img/heart.svg";

const Footer = () => {
    return (
        <footer className="text-center mt-2 mb-3">
            <div className="d-flex justify-content-center">
                Făcut cu <HeartIcon className="mx-1" /> pentru România.
            </div>
        </footer>
    );
};

export default Footer;
