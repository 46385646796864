export enum RequestStatus {
    NEW = "new",
    APPROVED = "approved",
    DENIED = "denied",
    BANNED = "banned",
    VALIDATED = "validated",
    CLAIMED = "claimed",
    SENT = "sent",
    FAIL_SEND = "failSend",
}

export type UserRequest = {
    id: string;
    userID?: string;
    tel?: string;
    email?: string;
    walletAddress?: string;
    status: RequestStatus;
    firstName: string;
    lastName: string;
    cnp: string;
    physicalAddress: string;
    statusReason?: string;
    birthCertificateUrl?: string;
    idUrls?: string[];
    selfieUrl?: string;
    warrantUrls?: string[];
};
