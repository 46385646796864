import * as React from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  Button,
  Overlay,
  Tooltip,
  Form,
} from "react-bootstrap";
import {
  tokenIdentifier,
  denomination,
  tokenName,
  gatewayUrl,
} from "../../config";
import { useEffect, useState, useRef } from "react";
import {
  Address,
  TokenPayment,
  ESDTTransferPayloadBuilder,
} from "@multiversx/sdk-core";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { refreshAccount } from "@multiversx/sdk-dapp/utils/account";
import VectorDown from "assets/img/Vector-Down.svg";
import VectorUp from "assets/img/Vector-Up.svg";
import QRCode from "react-qr-code";
import { routeNames } from "routes";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers";
import { formatAmount } from "@multiversx/sdk-dapp/utils";

const WalletPage = () => {
  const { isAccountLoading, account } = useGetAccountInfo();
  const [tokenBalance, setTokenBalance] = useState("0");

  const [tokenValue, setTokenValue] = React.useState<number>();
  const [receiver, setReceiver] = useState("");
  const [showReceive, setShowReceive] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    let proxy = new ProxyNetworkProvider(gatewayUrl);
    proxy
      .getFungibleTokenOfAccount(new Address(account.address), tokenIdentifier)
      .then((res) => {
        setTokenBalance(
          res.balance.toNumber().toLocaleString("ro").replaceAll(".", "")
        );
      })
      .catch((e) => console.log(e));
  }, [account]);

  const handleReceive = () => {
    setShowReceive(true);
  };

  const handleSend = () => {
    setShowSend(true);
  };

  const handleClose = () => {
    setShowReceive(false);
    setShowSend(false);
  };
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(account.address);
    setShowCopy(!showCopy);
  };

  const handleChangeReceiver = (e: any) => {
    setReceiver(e.target.value);
  };

  const handleChangeTokenValue = (e: any) => {
    const value = parseFloat(e.target.value) * Math.pow(10, denomination);
    const balance = parseInt(tokenBalance);
    if (value > balance) {
      setTokenValue(balance);
      return;
    }
    setTokenValue(value);
  };

  const handleSendToken = async () => {
    if (tokenValue) {
      let payment = TokenPayment.fungibleFromBigInteger(
        tokenIdentifier,
        tokenValue.toString(),
        denomination
      );
      let data = new ESDTTransferPayloadBuilder().setPayment(payment).build();
      refreshAccount();
      try {
        const transaction = {
          receiver: receiver,
          value: 0,
          data: data.toString(),
          nonce: account.nonce,
          gasLimit: 50000 + 1500 * data.length() + 500000,
        };
        const { sessionId } = await sendTransactions({
          transactions: transaction,
          transactionsDisplayInfo: {
            processingMessage: "Trimitere in curs",
            errorMessage: "S-a produs o eroare la tranzactie",
            successMessage: "Ai trimis eLEU cu succes.",
          },
          redirectAfterSign: false,
          callbackRoute: routeNames.wallet,
        });
        handleClose();
        setReceiver("");
      } catch (error) {
        console.log("nu merge");
        return;
      }
    }
  };

  if (isAccountLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Spinner animation="border" role="loading" />
      </div>
    );
  }

  return (
    <>
      <Modal show={showReceive} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="wallet-title">
            Primește {tokenName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="wallet-description">
            Aici este codul QR pentru adresa ta.
          </p>
          <QRCode value={account.address}></QRCode>
          <p className="mt-4 wallet-description">Adresa: </p>
          <p ref={target} onClick={handleCopyAddress}>
            {account.address}
          </p>
          <Overlay target={target.current} show={showCopy} placement="top">
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                Copiat!
              </Tooltip>
            )}
          </Overlay>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button variant="primary" onClick={handleClose}>
            Am terminat
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSend} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="wallet-title">
            Trimite {tokenName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Form.Group className="mb-3" controlId="formReceiver">
            <Form.Label className="form-label-send">
              Adresa destinatar
            </Form.Label>
            <Form.Control
              className="token-input"
              type="text"
              value={receiver}
              placeholder={
                "erd1n0xs3qya9czktgu0tph8hpwwmudg2q69cwg0uwwrlhkqx9exj3hsuz6uu9"
              }
              onChange={handleChangeReceiver}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSendAmount">
            <Form.Label className="form-label-send">
              Suma eLEU (folositi . pentru zecimale)
            </Form.Label>
            <Form.Control
              className="token-input"
              type="number"
              step={0.02}
              placeholder={"0.00"}
              onChange={handleChangeTokenValue}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button variant="secondary" onClick={handleClose}>
            Închide
          </Button>
          <Button variant="primary" onClick={handleSendToken}>
            Trimite
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="page-wraper">
        <Row>
          <Col>
            <h2 className="wallet-title wallet-row">Portofel</h2>
            <Row className="wallet-row">
              <Col md={3} xs={6}>
                {" "}
                <p className="wallet-description">Disponibil</p>
              </Col>
              <Col md={3} xs={6}>
                {" "}
                <p className="wallet-description">
                  {formatAmount({
                    input: tokenBalance,
                    decimals: denomination,
                  }) +
                    " " +
                    tokenName}
                </p>
              </Col>
            </Row>

            <h2 className="wallet-title wallet-row">Tranzacționează</h2>

            <Row className="wallet-row">
              <Col
                md={2}
                xs={6}
                className="text-center wallet-button"
                onClick={handleReceive}
              >
                <img src={VectorDown} />
                <p>Primește</p>
              </Col>
              <Col
                md={2}
                xs={6}
                className="text-center wallet-button"
                onClick={handleSend}
              >
                <img src={VectorUp} />
                <p>Trimite</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WalletPage;
