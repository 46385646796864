import React from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";
import { Navbar as BsNavbar, NavItem, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import ElrondLogo from "./../../../assets/img/logo.png";
import { SESSION_FORM_KEY } from "../../../pages/Dashboard";

const Navbar = () => {
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    // Remove saved request form details
    window.sessionStorage.removeItem(SESSION_FORM_KEY);
    localStorage.removeItem("token");
    logout(`${window.location.origin}/unlock`);
  };

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar className="light-bg border-bottom px-4 py-3">
      <div className="container-fluid">
        <Link
          className="d-flex align-items-center navbar-brand mr-0"
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <img
            src={ElrondLogo}
            alt="eLeu logo"
            style={{ height: 64, width: 64 }}
          />
        </Link>

        <Nav className="ml-auto">
          {isLoggedIn ? (
            <>
              <NavItem>
                <Link
                  className="d-flex align-items-center navbar-brand mr-0"
                  to={routeNames.wallet}
                >
                  <Button className="text-gold light-bg" variant="primary">
                    Portofel
                  </Button>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="d-flex align-items-center navbar-brand mr-0"
                  to={routeNames.requests}
                >
                  <Button className="text-gold light-bg" variant="primary">
                    Cereri
                  </Button>
                </Link>
              </NavItem>
              <NavItem className="d-flex align-items-center">
                <Button
                  className="text-gold light-bg"
                  variant="primary"
                  onClick={handleLogout}
                >
                  Deconectare
                </Button>
              </NavItem>
            </>
          ) : (
            <NavItem>
              <a href={routeNames.unlock}>
                <Button
                  className="text-gold light-bg"
                  variant="primary"
                  data-testid="loginBtn"
                >
                  Autentificare
                </Button>
              </a>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
