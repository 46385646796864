import FormikFileInput from "../FormikFileInput";
import FormikCheckbox from "../FormikCheckbox";
import * as React from "react";
import { UserRequest } from "../../pages/models/UserRequest";

const StepIdentification = (props: {
    existingRequest?: UserRequest | null;
}) => {
    return (
        <>
            {/* <FormikFileInput
            accept="image/*"
            maxFiles="1"
            name="birthCertificate"
            label="Certificat de naștere"
        /> */}
            <FormikFileInput
                accept="image/*"
                maxFiles="1"
                name="selfie"
                label="Te rugăm fă un selfie cu tine în care ții buletinul în mână."
                capture="user"
            />
            <FormikFileInput
                accept="image/*"
                maxFiles="1"
                name="idPhotoFront"
                label="Poză la fața buletinului"
                capture="environment"
            />
            <FormikFileInput
                accept="image/*"
                maxFiles="1"
                name="idPhotoBack"
                label="Poză la spatele buletinului"
                capture="environment"
            />

            {!props.existingRequest && (
                <FormikCheckbox
                    name="acceptTerms"
                    label="Accept <a
          href='https://eleu.digital/termeni-si-conditii/'
          target='_blank'
          rel='noreferrer'
      >termenii și condițiile</a> pentru utilizarea platformei dMfv."
                />
            )}
        </>
    );
};

export default StepIdentification;
