import "./index.css";
import "cropperjs/dist/cropper.min.css";

import { createRoot } from "react-dom/client";
import App from "./App";
import "./assets/sass/theme.scss";
import UserContextProvider from "components/contexts/UserContext";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <UserContextProvider>
    <App />
  </UserContextProvider>
);
