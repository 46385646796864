import { useField } from "formik";
import { Form } from "react-bootstrap";
import * as React from "react";
import Feedback from "react-bootstrap/Feedback";

const FormikCheckbox = (props: { name: string; label: string }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" });
    return (
        <Form.Group>
            <Form.Check id={props.name}>
                <Form.Check.Input
                    type="checkbox"
                    {...field}
                    {...props}
                    isInvalid={!!meta.error}
                />
                <Form.Check.Label
                    dangerouslySetInnerHTML={{ __html: props.label }}
                ></Form.Check.Label>
                <Feedback type="invalid">{meta.error}</Feedback>
            </Form.Check>
        </Form.Group>
    );
};

export default FormikCheckbox;
