import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Cropper from "cropperjs";

const imgStyle = {
  display: "block",
  maxWidth: "100%",
};

const PhotoCrop = (props: {
  photo: File;
  addImage: (blob: Blob, originalFile: File) => any;
  dismiss: () => any;
}) => {
  const [show, setShow] = useState(true);
  const [preview, setPreview] = useState("");
  const { addImage, photo, dismiss } = props;
  const cropper = useRef<Cropper | null>(null);

  useEffect(() => {
    setPreview(URL.createObjectURL(props.photo));
  }, [props.photo]);

  useEffect(() => {
    if (preview) {
      const element = document.getElementById("editing-image");
      cropper.current = new Cropper(element as HTMLImageElement, {
        autoCropArea: 1,
        viewMode: 3,
      });
    }
  }, [preview]);

  const okPressed = useCallback(() => {
    const currentCropper = cropper.current;
    if (!currentCropper) {
      return;
    }

    currentCropper.getCroppedCanvas({ maxWidth: 1024 }).toBlob(
      (blob) => {
        if (!blob) {
          return;
        }
        addImage(blob, photo);
        setShow(false);
      },
      "image/jpeg",
      0.9
    );
  }, [addImage, photo]);

  const cancelPressed = useCallback(() => {
    dismiss();
    setShow(false);
  }, [dismiss]);

  return (
    <Modal show={show} backdrop="static" onHide={cancelPressed}>
      <Modal.Header>
        <Modal.Title>Încarcă poză</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            id="editing-image"
            src={preview}
            style={imgStyle}
            alt="Currently editing"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelPressed}>
          Anulează
        </Button>
        <Button variant="primary" onClick={okPressed}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PhotoCrop;
