import * as React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { backendURL } from "../../config";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import { getAuthHeaders } from "utils/headers";

enum ValidateStatus {
    Waiting,
    Success,
    Failure,
}

const ValidatePage = () => {
    const [status, setStatus] = useState(ValidateStatus.Waiting);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${backendURL}/requests/validate/${id}`, {
                headers: getAuthHeaders(),
            })
            .then((validateResponse) => {
                if (validateResponse.status === 200) {
                    setStatus(ValidateStatus.Success);
                } else {
                    setStatus(ValidateStatus.Failure);
                }
            })
            .catch(() => {
                setStatus(ValidateStatus.Failure);
            });
    }, [id]);

    return (
        <Container className="d-flex flex-fill align-items-center">
            <Row className="w-100">
                <Col md={8} lg={5} className="mx-auto">
                    <Card className="shadow-sm rounded p-4 border-0">
                        <Card.Body className="text-center d-flex flex-column justify-content-center">
                            <span className="h4 empty-heading mt-3">
                                {status === ValidateStatus.Waiting &&
                                    "Cererea ta este în curs de validare..."}
                                {status === ValidateStatus.Success &&
                                    "Felicitări, cererea ta a fost validată! Vom verifica cererea ta și vom reveni cu un mail."}
                                {status === ValidateStatus.Failure &&
                                    "A intervenit o problemă în verificarea cererii tale. Te rugăm să încerci din nou."}
                            </span>
                            <Button
                                onClick={() => navigate(routeNames.requests)}
                            >
                                Cererile tale
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ValidatePage;
