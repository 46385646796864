import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Transaction from "./pages/Transaction";
import UnlockPage from "./pages/UnlockPage";
import Validate from "./pages/Validate";
import RequestPage from "./pages/Request";
import RequestsPage from "./pages/Requests";
import EditRequestPage from "./pages/Request/edit";
import WalletPage from "pages/Wallet";

export const routeNames = {
  home: "/",
  dashboard: "/dashboard",
  transaction: "/transaction",
  unlock: "/unlock",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
  signUp: "/signup",
  validate: "/validate/:id",
  request: "/request",
  editrequest: "/request/edit",
  requests: "/requests",
  wallet: "/wallet"
};

const routes: Array<any> = [
  {
    path: routeNames.validate,
    title: "Validare",
    component: Validate,
    authenticatedRoute: true,
  },
  {
    path: routeNames.home,
    title: "Home",
    component: Home,
  },
  {
    path: routeNames.dashboard,
    title: "Dashboard",
    component: Dashboard,
    authenticatedRoute: true,
  },
  {
    path: routeNames.transaction,
    title: "Transaction",
    component: Transaction,
  },
  {
    path: routeNames.unlock,
    title: "Conectare",
    component: UnlockPage,
  },
  {
    path: routeNames.request,
    title: "Cerere",
    component: RequestPage,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editrequest,
    title: "Editează cererea",
    component: EditRequestPage,
    authenticatedRoute: true,
  },
  {
    path: routeNames.requests,
    title: "Cereri",
    component: RequestsPage,
    authenticatedRoute: true,
  },
  {
    path: routeNames.wallet,
    title: "Portofel",
    component: WalletPage,
    authenticatedRoute: true,
  },
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
