import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Container, Spinner } from "react-bootstrap";
import { array, bool, object, string } from "yup";
import { backendURL } from "../../config";
import { RequestStatus, UserRequest } from "pages/models/UserRequest";
import StepAuth from "../../components/RequestForm/StepAuth";
import StepDetails from "../../components/RequestForm/StepDetails";
import StepIdentification from "../../components/RequestForm/StepIdentification";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routeNames } from "../../routes";
import { initYup } from "../../utils/yup";

import { getAuthHeaders } from "utils/headers";
// TODO: only allow if denied

initYup();
const validationSchema = object().shape({
  email: string()
    .required("Adresa de mail este obligatorie!")
    .email("Te rugăm să te asiguri că ai introdus corect adresa de mail."),
  tel: string()
    .required("Numărul de telefon este obligatoriu!")
    .matches(
      /^\+?[0-9]{10,14}$/,
      "Te rugăm să te asiguri că ai introdus corect numărul de telefon."
    ),
  // We use the weird any hack for the cnp method to be recognized.
  cnp: (
    string()
      .trim()
      .matches(
        /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/g,
        "Te rugăm să te asiguri că ai introdus CNP-ul corect."
      )
      .length(13, "Te rugăm să te asiguri că ai introdus CNP-ul corect.")
      .required("CNP-ul este obligatoriu!") as any
  ).cnp("Te rugăm să te asiguri că ai introdus CNP-ul corect."),
  lastName: string()
    .trim()
    .matches(
      /^[a-zA-ZăîșțzâĂÎȘȚZÂ\-'` ]*$/,
      "Te rugăm să te asiguri că ai introdus numele de familie corect, folosind doar litere și cratime."
    )
    .required("Numele de familie este obligatoriu!"),
  firstName: string()
    .trim()
    .matches(
      /^[a-zA-ZăîșțzâĂÎȘȚZÂ\-'` ]*$/,
      "Te rugăm să te asiguri că ai introdus prenumele corect, folosind doar litere și cratime."
    )
    .required("Prenumele este obligatoriu!"),
  physicalAddress: string().required("Adresa este obligatorie!"),
  selfie: array()
    .required()
    .test({
      message: "Încărcarea unui selfie este obligatorie!",
      test: (arr) => arr != null && arr.length === 1,
    })
    .typeError("Încărcarea unui selfie este obligatorie!"),
  idPhotoFront: array()
    .required()
    .test({
      message: "Încărcarea pozei cu fața buletinului este obligatorie!",
      test: (arr) => arr != null && arr.length === 1,
    })
    .typeError("Încărcarea pozei cu fața buletinului este obligatorie!"),
  idPhotoBack: array()
    .required()
    .test({
      message: "Încărcarea pozei cu spatele buletinului este obligatorie!",
      test: (arr) => arr != null && arr.length === 1,
    })
    .typeError("Încărcarea pozei cu spatele buletinului este obligatorie!"),
  acceptTerms: bool().oneOf(
    [true],
    "Trebuie să acceptați termenii și condițiile pentru a continua."
  ),
});

const EditRequestPage = (props: { id: string }) => {
  const navigate = useNavigate();
  const [existingUserData, setExistingUserData] = useState<{
    tel: string;
    email: string;
    id: string;
    walletAddress: string;
    role: string;
  } | null>(null);
  const [existingRequest, setExistingRequest] = useState<UserRequest | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const [loadingForm, setLoadingForm] = useState(true);

  const proceed = async (values: any, otherProps: FormikHelpers<any>) => {
    otherProps.setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("cnp", values.cnp);
      formData.append("physicalAddress", values.physicalAddress);

      if (typeof values.selfie[0] !== "string") {
        formData.append("selfie", values.selfie[0]);
      }

      if (typeof values.idPhotoFront[0] !== "string") {
        formData.append("idFront", values.idPhotoFront[0]);
      }

      if (typeof values.idPhotoBack[0] !== "string") {
        formData.append("idBack", values.idPhotoBack[0]);
      }

      let postReqResponse = await axios.put(
        `${backendURL}/requests/${existingRequest!.id}`,
        formData,
        { headers: getAuthHeaders() }
      );
      if (postReqResponse.status === 201 || postReqResponse.status === 202) {
        navigate(routeNames.request);
        return;
      }

      alert(
        "A apărut o eroare la trimiterea formularului. Te rugăm să încerci din nou iar dacă tot nu funcționează să contactezi departamentul de suport."
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        switch (e.response?.data.code) {
          case "DUPLICATE_USER_REQUEST":
            alert("Există deja o cerere pentru acest utilizator!");
            break;
          case "DUPLICATE_CNP":
            alert("Există deja o cerere pentru CNP!");
            break;
          case "BAD_FIELD":
            alert(
              `Te rugăm să verifici datele din câmpul unde ai introdus ${e.response.data.value} și să încerci din nou.`
            );
            break;
          default:
            alert(
              "A apărut o eroare la trimiterea formularului. Te rugăm să încerci din nou iar dacă tot nu funcționează să contactezi departamentul de suport."
            );
            window.location.reload();
        }
      }
    } finally {
      otherProps.setSubmitting(false);
    }

    otherProps.setSubmitting(false);
  };

  const userAlreadyExists = useCallback(async () => {
    try {
      const id = parseInt(searchParams.get("id") ?? "NaN");
      if (isNaN(id)) {
        navigate(routeNames.dashboard);
        return;
      }

      const userResponse = await axios.get(`${backendURL}/auth/me`, {
        headers: getAuthHeaders(),
      });

      if (userResponse.status === 200) {
        if (
          !userResponse.data.data?.requests?.length ||
          userResponse.data.data.requests.length <= id
        ) {
          navigate(routeNames.dashboard);
          return;
        }

        // The user should only edit their request if it was denied.
        const request = userResponse.data.data.requests[id] as UserRequest;
        if (request.status !== RequestStatus.DENIED) {
          navigate(routeNames.request + "?id=" + id);
          return;
        }

        setExistingUserData(userResponse.data.data);
        setExistingRequest(request);
      }
    } finally {
      setLoadingForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userAlreadyExists();
  }, [userAlreadyExists]);

  if (loadingForm) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Spinner animation="border" role="loading" />
      </div>
    );
  }

  return (
    <>
      <Container className="py-4">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: existingUserData?.email ?? "",
            tel: existingUserData?.tel ?? "",
            firstName: existingRequest?.firstName ?? "",
            lastName: existingRequest?.lastName ?? "",
            physicalAddress: existingRequest?.physicalAddress ?? "",
            // birthCertificate: existingRequest?.birthCertificateUrl
            //     ? [existingRequest?.birthCertificateUrl]
            //     : null,
            cnp: existingRequest?.cnp ?? "",
            selfie: [existingRequest?.selfieUrl],
            idPhotoFront: [existingRequest?.idUrls![0]],
            idPhotoBack: [existingRequest?.idUrls![1]],
          }}
          onSubmit={proceed}
        >
          {({ isSubmitting }) => (
            <Form noValidate className="mx-auto">
              <StepAuth existingUserData={existingUserData} />
              <StepDetails existingRequest={existingRequest} />
              <StepIdentification existingRequest={existingRequest} />

              <div>
                <div>
                  {isSubmitting && (
                    <Spinner
                      animation="border"
                      role="loading"
                      className="mr-3"
                    />
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Trimite
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default EditRequestPage;
