import * as React from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Alert,
  Spinner,
  Button,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { backendURL, contractAddress, apiUrl, claimDays } from "../../config";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import RequestStatusView from "components/RequestStatusView";
import { getAuthHeaders } from "utils/headers";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers";
import { contractAbi } from "utils/contractAbi";

import {
  Address,
  ContractFunction,
  AddressValue,
  SmartContract,
  AbiRegistry,
  SmartContractAbi,
  ResultsParser,
} from "@multiversx/sdk-core";

const RequestsPage = () => {
  const navigate = useNavigate();
  const [canClaim, setCanClaim] = useState(false);

  const [loading, setLoading] = useState(false);
  const [resendSuccessful, setResendSuccessful] = useState(false);
  const [existingRequests, setExistingRequests] = useState<
    | {
        id: string;
        firstName: string;
        lastName: string;
        cnp: string;
        physicalAddress: string;
        status: string;
        statusReason: string;
        createdAt: string;
      }[]
    | null
  >(null);
  const { account, address } = useGetAccountInfo();

  const userAlreadyExists = useCallback(async () => {
    try {
      const userResponse = await axios.get(`${backendURL}/auth/me`, {
        headers: getAuthHeaders(),
      });
      if (userResponse.status === 200) {
        if (
          userResponse.data.data.requests &&
          userResponse.data.data.requests.length > 0
        ) {
          setExistingRequests(userResponse.data.data.requests);
        } else {
          navigate(routeNames.dashboard);
        }
      }
    } catch (e) {
      navigate(routeNames.dashboard);
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const resendVerificationMail = useCallback(async () => {
    try {
      const hasRequest = existingRequests && existingRequests.length > 0;
      if (!hasRequest) {
        return;
      }
      setLoading(true);

      const userResponse = await axios.get(
        `${backendURL}/requests/resend/${existingRequests![0].id}`,
        { headers: getAuthHeaders() }
      );
      if (userResponse.status >= 400) {
        alert(
          "A apărut o eroare în retrimiterea mailului. Te rugăm să încerci din nou."
        );
      } else {
        setResendSuccessful(true);
      }
    } catch (e) {
      alert(
        "A apărut o eroare în retrimiterea mailului. Te rugăm să încerci din nou."
      );
    } finally {
      setLoading(false);
    }
  }, [existingRequests]);

  useEffect(() => {
    userAlreadyExists();
  }, [userAlreadyExists]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Spinner animation="border" role="loading" />
      </div>
    );
  }

  const handleRequestClick = (index: number) => {
    navigate(routeNames.request + "?id=" + index.toString());
  };

  const checkClaim = (existingRequest: any) => {
    if (existingRequest && !canClaim) {
      let abiRegistry = AbiRegistry.create(contractAbi);
      let abi = new SmartContractAbi(abiRegistry, ["MyContract"]);
      let contract = new SmartContract({
        address: new Address(contractAddress),
        abi: abi,
      });

      const query = contract.createQuery({
        func: new ContractFunction("request"),
        args: [new AddressValue(new Address(address))],
      });
      let networkProvider = new ProxyNetworkProvider(apiUrl);

      fetch(apiUrl + "/stats").then((response) => {
        response.json().then((stats) => {
          networkProvider
            .queryContract(query)
            .then((response) => {
              // const [encoded] = response.returnData;
              // const decoded = Buffer.from(encoded, "base64").toString();
              // console.log(decoded);
              let resultsParser = new ResultsParser();
              let endpointDefinition = contract.getEndpoint("request");
              let parsedResults = resultsParser.parseQueryResponse(
                response,
                endpointDefinition
              );
              if (parsedResults.values) {
                let values = parsedResults.values[0].valueOf();
                let claims = parseInt(values.request_claims.toString());
                let claimEpoch = parseInt(values.request_epoch.toString());
                const requiredEpoch = claimEpoch + claimDays * claims;
                if (stats.epoch > requiredEpoch) {
                  setCanClaim(true);
                }
              }
            })
            .catch((err) => {
              console.error("Unable to call VM query", err);
            });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      {existingRequests && (
        <Alert variant="info" show={existingRequests[0].status === "new"}>
          <Alert.Heading>Mai ai încă un pas</Alert.Heading>

          <p>
            Accesează link-ul primit pe mail și cererea ta va fi gata. Nu ai
            primit mailul?{" "}
            <Button variant="link" onClick={resendVerificationMail}>
              Apasă aici pentru a îl retrimite.
            </Button>
          </p>
        </Alert>
      )}
      <ToastContainer position="top-center" className="p-3">
        <Toast
          onClose={() => setResendSuccessful(false)}
          show={resendSuccessful}
          delay={6000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <strong className="me-auto">Succes</strong>
          </Toast.Header>
          <Toast.Body>
            Mailul a fost retrimis cu succes. Dacă nu îl găsești, nu uita să
            verifici și folderul Spam.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container className="page-wraper">
        <Row>
          <Col>
            <h3>Cererile Tale</h3>
            <table className="requests-table">
              <thead>
                <tr>
                  <th>Beneficiar</th>
                  <th>Data</th>
                  <th>Stare</th>
                </tr>
              </thead>
              <tbody>
                {existingRequests &&
                  existingRequests.map((request, index) => {
                    checkClaim(request);
                    return (
                      <tr
                        key={request.id}
                        onClick={() => handleRequestClick(index)}
                      >
                        <td>
                          {request.firstName} {request.lastName}
                        </td>
                        <td>{request.createdAt}</td>
                        <td>
                          <RequestStatusView
                            status={request.status}
                            statusReason={request.statusReason}
                            canClaim={canClaim}
                          ></RequestStatusView>
                        </td>
                        <td>
                          <Button variant="link">Vezi cererea</Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RequestsPage;
