import {useField} from "formik";
import {Form} from "react-bootstrap";
import * as React from "react";

const FormikInput = (props: { name: string, label: string, placeholder: string, type?: string, disabled?: boolean }) => {
    const [field, meta] = useField(props);
    return <Form.Group controlId={props.name}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control type={props.type ?? "text"}
                      {...field} {...props}
                      isInvalid={meta.touched && !!meta.error}
                      disabled={props.disabled}
        />
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
}

export default FormikInput;