import * as React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";

const PageNotFound = () => {
    const { pathname } = useLocation();
    return (
        <Container className="d-flex flex-fill align-items-center">
            <Row className="w-100">
                <Col md={8} lg={5} className="mx-auto">
                    <Card className="shadow-sm rounded p-4 border-0">
                        <Card.Body className="text-center d-flex flex-column justify-content-center">
                            <div className="dapp-icon icon-medium">
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    className="mx-auto text-muted fa-3x mb-2"
                                />
                            </div>
                            <span className="h4 empty-heading mt-3">
                                Page not found
                            </span>
                            <span className="empty-details">{pathname}</span>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PageNotFound;
