import { backendURL } from "config";
import axios from "axios";
import { RequestStatus } from "pages/models/UserRequest";
import { getAuthHeaders } from "utils/headers";

export async function updateStatusClaim(id: string, paymentTx: string) {
    let response = await axios.put(
        backendURL + "/requests/claim/" + id,
        {
            status: RequestStatus.CLAIMED,
            paymentTx: paymentTx,
        },
        { headers: getAuthHeaders() }
    );

    return response;
}
