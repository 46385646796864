import { RequestStatus } from "pages/models/UserRequest";

const RequestStatusView = ({
    status,
    statusReason,
    canClaim = false,
}: {
    status: string;
    statusReason: string;
    canClaim?: boolean;
}) => {
    return (
        <>
            {status === RequestStatus.NEW && (
                <span className="request-new">Confirmă pe email</span>
            )}
            {status === RequestStatus.BANNED && (
                <>
                    <span className="request-banned">anulată</span>
                    <br />
                    <span>Motiv {statusReason}</span>
                </>
            )}
            {status === RequestStatus.DENIED && (
                <>
                    <span className="request-denied">refuzată</span>
                    <br />
                    <span>Motiv: {statusReason}</span>
                </>
            )}
            {status === RequestStatus.VALIDATED && (
                <span className="request-validated">în așteptare</span>
            )}
            {status === RequestStatus.APPROVED && (
                <span className="request-approved">aprobată</span>
            )}
            {status === RequestStatus.CLAIMED && (
                <span className="request-approved">
                    Revendicare in curs de procesare
                </span>
            )}
            {status === RequestStatus.SENT && !canClaim && (
                <span className="request-approved">
                    Transa a fost procesata.
                    <br />
                    Verifică-ti portofelul pentru a vedea eLeii disponibili.
                </span>
            )}
            {status === RequestStatus.SENT && canClaim && (
                <span className="request-approved">
                    Poti revendica următoarea tranșă
                </span>
            )}
            {status === RequestStatus.FAIL_SEND && (
                <>
                    <span className="request-denied">
                        Revendicarea nu a putut fi procesata.<br></br> Te rugam
                        sa contactezi suport-ul.
                    </span>
                </>
            )}
        </>
    );
};

export default RequestStatusView;
