import * as React from "react";
import axios from "axios";
import { Container, Row, Col, Image, Spinner, Button } from "react-bootstrap";
import RequestStatusView from "components/RequestStatusView";
import {
  backendURL,
  requesterAddress,
  egldDenomination,
  contractAddress,
  apiUrl,
  claimDays,
} from "../../config";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { routeNames } from "routes";
import { RequestStatus } from "pages/models/UserRequest";
import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useTrackTransactionStatus,
} from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers";
import { refreshAccount } from "@multiversx/sdk-dapp/utils/account";
import { sendTransactions } from "@multiversx/sdk-dapp/services";

import {
  Address,
  ContractFunction,
  AddressValue,
  SmartContract,
  AbiRegistry,
  SmartContractAbi,
  ResultsParser,
} from "@multiversx/sdk-core";
import { updateStatusClaim } from "services/requestService";
import { getAuthHeaders } from "utils/headers";
import { contractAbi } from "utils/contractAbi";

const RequestPage = () => {
  const [loading, setLoading] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [canClaim, setCanClaim] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstEgldValue, setFirstEgldValue] = useState(0.0005);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { network } = useGetNetworkConfig();

  const [existingRequest, setExistingRequest] = useState<{
    id: string;
    firstName: string;
    lastName: string;
    cnp: string;
    physicalAddress: string;
    status: string;
    statusReason: string;
    createdAt: string;
    birthCertificateUrl: string;
    idUrls: string[];
    selfieUrl: string;
  } | null>(null);

  const [transactionSessionId, setTransactionSessionId] = React.useState<
    string | null
  >(null);

  // const { network } = useGetNetworkConfig();
  const { account, address } = useGetAccountInfo();

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId,
  });

  React.useEffect(() => {
    if (
      transactionStatus.isSuccessful &&
      existingRequest &&
      transactionStatus.transactions &&
      isClaimed === false
    ) {
      if (canClaim !== true)
        updateStatusClaim(
          existingRequest.id,
          transactionStatus.transactions[0].hash
        ).then((response) => {
          setIsClaimed(true);
        });
      else {
        setIsClaimed(true);
        setCanClaim(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus]);

  React.useEffect(() => {
    if (existingRequest && !canClaim) {
      let abiRegistry = AbiRegistry.create(contractAbi);
      let abi = new SmartContractAbi(abiRegistry, ["MyContract"]);
      let contract = new SmartContract({
        address: new Address(contractAddress),
        abi: abi,
      });

      const query = contract.createQuery({
        func: new ContractFunction("request"),
        args: [new AddressValue(new Address(address))],
      });
      let networkProvider = new ProxyNetworkProvider(apiUrl);

      fetch(apiUrl + "/stats").then((response) => {
        response.json().then((stats) => {
          networkProvider
            .queryContract(query)
            .then((response) => {
              // const [encoded] = response.returnData;
              // const decoded = Buffer.from(encoded, "base64").toString();
              // console.log(decoded);
              let resultsParser = new ResultsParser();
              let endpointDefinition = contract.getEndpoint("request");
              let parsedResults = resultsParser.parseQueryResponse(
                response,
                endpointDefinition
              );
              if (parsedResults.values) {
                let values = parsedResults.values[0].valueOf();
                let claims = parseInt(values.request_claims.toString());
                let claimEpoch = parseInt(values.request_epoch.toString());
                const requiredEpoch = claimEpoch + claimDays * claims;
                if (stats.epoch > requiredEpoch) {
                  setCanClaim(true);
                }
              }
            })
            .catch((err) => {
              console.error("Unable to call VM query", err);
            });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingRequest]);

  const userAlreadyExists = useCallback(async () => {
    const id = searchParams.get("id");
    if (id) {
      try {
        const userResponse = await axios.get(`${backendURL}/auth/me`, {
          headers: getAuthHeaders(),
        });
        if (userResponse.status === 200) {
          if (
            userResponse.data.data.requests &&
            userResponse.data.data.requests.length > 0 &&
            userResponse.data.data.requests[id]
          ) {
            setExistingRequest(userResponse.data.data.requests[id]);
            if (
              userResponse.data.data.requests[id].status === RequestStatus.SENT
            ) {
              setIsClaimed(true);
            }
          } else {
            navigate(routeNames.requests);
          }
        }
      } catch {
        navigate(routeNames.home);
      } finally {
        setLoading(false);
      }
    } else {
      navigate(routeNames.requests);
    }

    const sessionId = searchParams.get("signSession");
    if (sessionId) {
      setTransactionSessionId(sessionId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClaimed]);

  useEffect(() => {
    userAlreadyExists();
  }, [userAlreadyExists]);

  const firstClaim = async () => {
    const amount = egldDenomination * firstEgldValue;
    await refreshAccount();
    const nonce = account.nonce;
    const transaction = {
      value: amount,
      receiver: requesterAddress,
      nonce: nonce,
      gasLimit: 50000,
    };

    const { sessionId } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: "Revendicare in curs",
        errorMessage: "S-a produs o eroare la tranzactie",
        successMessage: "Revendicare cu succes.",
      },
      redirectAfterSign: false,
      callbackRoute: location.pathname + location.search,
    });

    setTransactionSessionId(sessionId);
  };

  const nextClaim = async () => {
    await refreshAccount();
    const nonce = account.nonce;
    const transaction = {
      value: 0,
      receiver: contractAddress,
      data: "claimRequest",
      nonce: nonce,
      gasLimit: 10000000,
    };

    const { sessionId } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: "Revendicare in curs",
        errorMessage: "S-a produs o eroare la tranzactie",
        successMessage: "Revendicare cu succes.",
      },
      redirectAfterSign: false,
      callbackRoute: location.pathname + location.search,
    });

    setTransactionSessionId(sessionId);
  };

  const editRequest = () => {
    const id = searchParams.get("id");
    if (!id) {
      return;
    }
    navigate(routeNames.editrequest + "?id=" + id);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Spinner animation="border" role="loading" />
      </div>
    );
  }

  return (
    <>
      <Container className="page-wraper">
        <Row>
          <Col md={{ span: 6, order: 1 }} xs={{ order: 2 }}>
            {existingRequest && (
              <>
                <h4 className="request-title">
                  Detalii cerere {existingRequest.cnp}
                </h4>

                <table className="request-table">
                  <tbody>
                    <tr>
                      <td className="request-key">Prenume</td>
                      <td className="request-value">
                        {existingRequest.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="request-key">Nume</td>
                      <td className="request-value">
                        {existingRequest.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="request-key">CNP</td>
                      <td className="request-value">{existingRequest.cnp}</td>
                    </tr>
                    <tr>
                      <td className="request-key">Adresa</td>
                      <td className="request-value">
                        {existingRequest.physicalAddress}
                      </td>
                    </tr>
                    <tr>
                      <td className="request-key">Data</td>
                      <td className="request-value">
                        {existingRequest.createdAt}
                      </td>
                    </tr>
                    <tr>
                      <td className="request-key">Status</td>
                      <td className="request-value">
                        <RequestStatusView
                          status={existingRequest.status}
                          statusReason={existingRequest.statusReason}
                          canClaim={canClaim}
                        ></RequestStatusView>
                      </td>
                    </tr>
                    {/* <tr>
                                        <td className="request-key">
                                            Certificat naștere
                                        </td>
                                        <td className="request-value">
                                            <Image
                                                fluid
                                                src={
                                                    existingRequest.birthCertificateUrl
                                                }
                                            ></Image>
                                        </td>
                                    </tr> */}
                    <tr>
                      <td className="request-key">Buletin față</td>
                      <td className="request-value">
                        <Image fluid src={existingRequest.idUrls[0]}></Image>
                      </td>
                    </tr>
                    <tr>
                      <td className="request-key">Buletin spate</td>
                      <td className="request-value">
                        <Image fluid src={existingRequest.idUrls[1]}></Image>
                      </td>
                    </tr>
                    <tr>
                      <td className="request-key">Selfie</td>
                      <td className="request-value">
                        <Image fluid src={existingRequest.selfieUrl}></Image>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </Col>
          <Col md={{ span: 6, order: 2 }} xs={{ order: 1 }}>
            {existingRequest &&
              existingRequest.status === RequestStatus.APPROVED && (
                <>
                  <h4 className="request-title">
                    Felicitari! Cerera ta a fost aprobată.
                  </h4>
                  <p>
                    Mai ai un singur pas. Click pe butonul de mai jos si vei
                    primi prima tranșă in maxim 5 minute. <br />
                    Trebuie sa ai egold necesar taxei de tranzactie cu smart
                    contract-ul eLeu.
                  </p>

                  <Button
                    variant="success"
                    className="float-right"
                    onClick={() => firstClaim()}
                    disabled={transactionStatus.isPending}
                  >
                    Revendică
                  </Button>

                  {transactionStatus.isPending && (
                    <p>
                      Procesare in curs... <br />
                      Te rugam nu inchide pagina
                    </p>
                  )}
                  {transactionStatus.isSuccessful && (
                    <p>Felicitari, revendicarea a fost procesata</p>
                  )}
                  {transactionStatus.isFailed && (
                    <p>A aparut o eroare, incearca din nou</p>
                  )}
                </>
              )}
            {existingRequest &&
              existingRequest.status === RequestStatus.SENT &&
              canClaim && (
                <>
                  <h4 className="request-title">
                    Poti revendica urmatoare transa de 1050 eLei!
                  </h4>
                  <p></p>

                  <Button
                    variant="success"
                    className="float-right"
                    onClick={() => nextClaim()}
                    disabled={transactionStatus.isPending}
                  >
                    Revendică următoare tranșă
                  </Button>

                  {transactionStatus.isPending && (
                    <p>
                      Procesare in curs... <br />
                      Te rugam nu inchide pagina
                    </p>
                  )}
                  {transactionStatus.isSuccessful && (
                    <p>Felicitari, revendicarea a fost procesata</p>
                  )}
                  {transactionStatus.isFailed && (
                    <p>A aparut o eroare, incearca din nou</p>
                  )}
                </>
              )}
          </Col>
        </Row>
        <Row>
          {existingRequest &&
            (existingRequest?.status === RequestStatus.NEW ||
              existingRequest?.status === RequestStatus.DENIED ||
              existingRequest?.status === RequestStatus.VALIDATED) && (
              <Col>
                <Button
                  className="btn-large light-bg text-gold"
                  onClick={editRequest}
                >
                  Modifică
                </Button>
              </Col>
            )}
        </Row>
      </Container>
    </>
  );
};

export default RequestPage;
