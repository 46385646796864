export const gasPerDataByte = 1500;
export const timeout = 10000; // 10 sec

export const contractAddress =
    "erd1qqqqqqqqqqqqqpgqxudxutup74cqqphhwts06y4rtmgq09ckqekqkaas8y";

export const requesterAddress =
    "erd1jrhe6v2x7z22akhnjnfxzg57m9y430sxuc5r5e4arpwzp4r4eknsqlypzl";

export const egldDenomination = 1000000000000000000;
export const dAppName = "eLeu";
export const tokenIdentifier = "LEU-c50baa";
export const denomination = 18;
export const claimDays = 60;
export const tokenName = "eLEU";

export const environment = "mainnet";
export const gatewayUrl = "https://gateway.elrond.com";
export const apiUrl = "https://api.elrond.com";
//export const backendURL = "http://localhost:8080";

export const backendURL = "https://vangov.eleu.tech";
