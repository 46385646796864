import * as React from "react";
import axios, { AxiosError } from "axios";
import { Form, FormikHelpers, Formik } from "formik";
import { Container, Button, Spinner } from "react-bootstrap";
import { object, string, bool, array } from "yup";
import { backendURL } from "../../config";
import { useCallback, useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import StepAuth from "../../components/RequestForm/StepAuth";
import StepDetails from "../../components/RequestForm/StepDetails";
import StepIdentification from "../../components/RequestForm/StepIdentification";
import { logout } from "@multiversx/sdk-dapp/utils";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../routes";
import { initYup } from "../../utils/yup";
import { getAuthHeaders } from "utils/headers";

export const SESSION_FORM_KEY = "currentForm";

initYup();
const validationSchemas = [
  object().shape({
    email: string()
      .required("Adresa de mail este obligatorie!")
      .email("Te rugăm să te asiguri că ai introdus corect adresa de mail."),
    tel: string()
      .required("Numărul de telefon este obligatoriu!")
      .matches(
        /^\+?[0-9]{10,14}$/,
        "Te rugăm să te asiguri că ai introdus corect numărul de telefon."
      ),
  }),
  // We use the weird any hack for the cnp method to be recognized.
  object().shape({
    cnp: (
      string()
        .trim()
        .matches(
          /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/g,
          "Te rugăm să te asiguri că ai introdus CNP-ul corect."
        )
        .length(13, "Te rugăm să te asiguri că ai introdus CNP-ul corect.")
        .required("CNP-ul este obligatoriu!") as any
    ).cnp("Te rugăm să te asiguri că ai introdus CNP-ul corect."),
    lastName: string()
      .trim()
      .matches(
        /^[a-zA-ZăîșțzâĂÎȘȚZÂ\-'` ]*$/,
        "Te rugăm să te asiguri că ai introdus numele de familie corect, folosind doar litere și cratime."
      )
      .required("Numele de familie este obligatoriu!"),
    firstName: string()
      .trim()
      .matches(
        /^[a-zA-ZăîșțzâĂÎȘȚZÂ\-'` ]*$/,
        "Te rugăm să te asiguri că ai introdus prenumele corect, folosind doar litere și cratime."
      )
      .required("Prenumele este obligatoriu!"),
    physicalAddress: string().required("Adresa este obligatorie!"),
  }),
  object().shape({
    // birthCertificate: array()
    //     .required()
    //     .test({
    //         message:
    //             "Încărcarea certificatului de naștere este obligatorie!",
    //         test: (arr) => arr != null && arr.length === 1,
    //     })
    //     .typeError(
    //         "Încărcarea certificatului de naștere este obligatorie!"
    //     ),
    selfie: array()
      .required()
      .test({
        message: "Încărcarea unui selfie este obligatorie!",
        test: (arr) => arr != null && arr.length === 1,
      })
      .typeError("Încărcarea unui selfie este obligatorie!"),
    idPhotoFront: array()
      .required()
      .test({
        message: "Încărcarea pozei cu fața buletinului este obligatorie!",
        test: (arr) => arr != null && arr.length === 1,
      })
      .typeError("Încărcarea pozei cu fața buletinului este obligatorie!"),
    idPhotoBack: array()
      .required()
      .test({
        message: "Încărcarea pozei cu spatele buletinului este obligatorie!",
        test: (arr) => arr != null && arr.length === 1,
      })
      .typeError("Încărcarea pozei cu spatele buletinului este obligatorie!"),
    acceptTerms: bool()
      .required(
        "Trebuie să acceptați termenii și condițiile pentru a continua."
      )
      .oneOf(
        [true],
        "Trebuie să acceptați termenii și condițiile pentru a continua."
      ),
  }),
];

enum Step {
  Auth,
  Details,
  Identification,
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(Step.Auth);
  const [existingUserData, setExistingUserData] = useState<{
    tel: string;
    email: string;
    id: string;
    walletAddress: string;
    role: string;
  } | null>(null);
  const [loadingForm, setLoadingForm] = useState(true);

  const deleteUser = useCallback(async () => {
    if (!existingUserData) {
      return;
    }
    const userResponse = await axios.delete(
      `${backendURL}/auth/${existingUserData.id}`,
      { headers: getAuthHeaders() }
    );
    if (userResponse.status !== 200) {
      // TODO: Report this
    }
  }, [existingUserData]);

  useEffect(() => {
    // Take user to the start of the form when pressing on the Back button
    const handlePopState = async () => {
      if (step === Step.Auth) {
        await logout(`${window.location.origin}/unlock`);
        return;
      }
      try {
        await deleteUser();
        setExistingUserData(null);
      } finally {
        window.history.pushState(null, document.title, window.location.href);
        setStep(Step.Auth);
      }
    };
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [step, existingUserData, deleteUser]);

  const proceed = async (values: any, otherProps: FormikHelpers<any>) => {
    switch (step) {
      case Step.Auth:
        if (existingUserData) {
          break;
        }

        let phoneNumber = values.tel;
        if (!phoneNumber.startsWith("+")) {
          // So the user won't have to fill in the +
          phoneNumber = "+4" + phoneNumber;
        }
        const userSignUpData = {
          tel: phoneNumber,
          email: values.email,
        };
        const signUpResponse = await axios.post(
          `${backendURL}/auth/`,
          userSignUpData,
          { headers: getAuthHeaders() }
        );
        if (signUpResponse.status !== 201) {
          alert(
            "A apărut o eroare la trimiterea formularului. Te rugăm să încerci din nou iar dacă tot nu funcționează să contactezi departamentul de suport."
          );
          window.location.reload();
        }
        setExistingUserData(signUpResponse.data);
        break;
      case Step.Details:
        const localSave = {
          firstName: values.firstName,
          lastName: values.lastName,
          cnp: values.cnp,
          physicalAddress: values.physicalAddress,
        };

        window.sessionStorage.setItem(
          SESSION_FORM_KEY,
          JSON.stringify(localSave)
        );
        break;
      case Step.Identification:
        await submitForm(values, otherProps);
        return;
    }

    setStep(step + 1);
    otherProps.setSubmitting(false);
  };

  const goBack = () => {
    if (step !== Step.Auth) {
      setStep(step - 1);
    }
  };

  const clearForm = async () => {
    let hasConfirmed = window.confirm(
      "Ești sigur(ă) ca dorești sa reîncepi formularul?"
    );
    if (!hasConfirmed) {
      return;
    }

    try {
      await deleteUser();
      window.sessionStorage.removeItem(SESSION_FORM_KEY);
      window.location.reload();
    } catch (e) {
      // TODO: Report
      alert(
        "A apărut o eroare. Am anunțat echipa și va fi rezolvată în cel mai scurt timp."
      );
    }
  };

  const submitForm = async (values: any, otherProps: FormikHelpers<any>) => {
    otherProps.setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("cnp", values.cnp);
      formData.append("physicalAddress", values.physicalAddress);

      // if (typeof values.birthCertificate[0] !== "string") {
      //     // A string type means that we got the URL from the edit request and no new photo has been uploaded.
      //     formData.append("birthCertificate", values.birthCertificate[0]);
      // }

      if (typeof values.selfie[0] !== "string") {
        formData.append("selfie", values.selfie[0]);
      }

      if (typeof values.idPhotoFront[0] !== "string") {
        formData.append("idFront", values.idPhotoFront[0]);
      }

      if (typeof values.idPhotoBack[0] !== "string") {
        formData.append("idBack", values.idPhotoBack[0]);
      }

      const postReqResponse = await axios.post(
        `${backendURL}/requests/`,
        formData,
        { headers: getAuthHeaders() }
      );
      if (postReqResponse.status === 201 || postReqResponse.status === 202) {
        window.sessionStorage.removeItem(SESSION_FORM_KEY);
        navigate(routeNames.request);
        return;
      }

      alert(
        "A apărut o eroare la trimiterea formularului. Te rugăm să încerci din nou iar dacă tot nu funcționează să contactezi departamentul de suport."
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        switch (e.response?.data.code) {
          case "DUPLICATE_USER_REQUEST":
            alert("Există deja o cerere pentru acest utilizator!");
            break;
          case "DUPLICATE_CNP":
            alert("Există deja o cerere pentru CNP!");
            break;
          case "BAD_FIELD":
            alert(
              `Te rugăm să verifici datele din câmpul unde ai introdus ${e.response.data.value} și să încerci din nou.`
            );
            break;
          default:
            alert(
              "A apărut o eroare la trimiterea formularului. Te rugăm să încerci din nou iar dacă tot nu funcționează să contactezi departamentul de suport."
            );
            window.location.reload();
        }
      }
    } finally {
      otherProps.setSubmitting(false);
    }
  };

  const userAlreadyExists = useCallback(async () => {
    try {
      const userResponse = await axios.get(`${backendURL}/auth/me`, {
        headers: getAuthHeaders(),
      });
      if (userResponse.status === 200) {
        if (
          userResponse.data.data.requests &&
          userResponse.data.data.requests.length > 0
        ) {
          navigate(routeNames.requests);
        }
        setExistingUserData(userResponse.data.data);
        setStep(Step.Details);
      }
    } catch (e) {
      // The user does not exist, we don't care.
    } finally {
      setLoadingForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userAlreadyExists();
  }, [userAlreadyExists]);

  if (loadingForm) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Spinner animation="border" role="loading" />
      </div>
    );
  }

  return (
    <>
      <Container className="py-4">
        <Formik
          validationSchema={validationSchemas[step]}
          initialValues={{
            email: existingUserData?.email ?? "",
            tel: existingUserData?.tel ?? "",
            firstName: "",
            lastName: "",
            physicalAddress: "",
            // birthCertificate: null
            cnp: "",
            selfie: null,
            idPhotoFront: null,
            idPhotoBack: null,
          }}
          onSubmit={proceed}
        >
          {({ isSubmitting }) => (
            <Form noValidate className="mx-auto">
              <Stepper
                steps={[
                  { label: "Login" },
                  { label: "Detalii personale" },
                  { label: "Identificare" },
                ]}
                activeStep={step}
              />
              {step === Step.Auth && (
                <StepAuth existingUserData={existingUserData} />
              )}
              {step === Step.Details && <StepDetails />}
              {step === Step.Identification && <StepIdentification />}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div>
                  {step !== Step.Auth && (
                    <Button
                      variant="primary"
                      onClick={goBack}
                      disabled={isSubmitting}
                    >
                      Înapoi
                    </Button>
                  )}
                  <Button
                    style={{ marginLeft: "1em" }}
                    variant="danger"
                    onClick={clearForm}
                    disabled={isSubmitting}
                  >
                    Resetează
                  </Button>
                </div>

                <div>
                  {step === Step.Identification && (
                    <>
                      {isSubmitting && (
                        <Spinner
                          animation="border"
                          role="loading"
                          className="mr-3"
                        />
                      )}
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Trimite
                      </Button>
                    </>
                  )}
                  {step !== Step.Identification && (
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Continuă
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Dashboard;
