import FormikInput from "../FormikInput";
import * as React from "react";

const StepAuth = (props: {
    existingUserData: any
}) => {

    return <>
        <FormikInput
            name="email"
            label="Adresă mail"
            placeholder="mail@example.com"
            type="email"
            disabled={!!props.existingUserData}
        />
        <FormikInput
            name="tel"
            label="Număr de telefon"
            placeholder="0700000000"
            type="tel"
            disabled={!!props.existingUserData}
        />
    </>;
}

export default StepAuth;