export const contractAbi = {
    buildInfo: {
        rustc: {
            version: "1.60.0-nightly",
            commitHash: "8cdb3cd94efece1e17cbd8f6edb1dc1a482779a0",
            commitDate: "2022-01-25",
            channel: "Nightly",
            short: "rustc 1.60.0-nightly (8cdb3cd94 2022-01-25)",
        },
        contractCrate: {
            name: "cash",
            version: "1.0.0",
            git_version: "bfb46aa-modified",
        },
        framework: {
            name: "elrond-wasm",
            version: "0.34.1",
        },
    },
    name: "DigitalCash",
    constructor: {
        inputs: [
            {
                name: "token_identifier",
                type: "EgldOrEsdtTokenIdentifier",
            },
            {
                name: "request_total_amount",
                type: "BigUint",
            },
            {
                name: "requester",
                type: "Address",
            },
            {
                name: "claimer",
                type: "Address",
            },
            {
                name: "epoch_period",
                type: "u64",
            },
        ],
        outputs: [],
    },
    endpoints: [
        {
            name: "makeRequest",
            mutability: "mutable",
            inputs: [
                {
                    name: "address",
                    type: "Address",
                },
            ],
            outputs: [],
        },
        {
            name: "banRequest",
            mutability: "mutable",
            inputs: [
                {
                    name: "address",
                    type: "Address",
                },
            ],
            outputs: [],
        },
        {
            name: "unbanRequest",
            mutability: "mutable",
            inputs: [
                {
                    name: "address",
                    type: "Address",
                },
            ],
            outputs: [],
        },
        {
            name: "claimRequest",
            mutability: "mutable",
            inputs: [],
            outputs: [],
        },
        {
            name: "deposit",
            mutability: "mutable",
            payableInTokens: ["*"],
            inputs: [],
            outputs: [],
        },
        {
            name: "claimAllEsdt",
            mutability: "mutable",
            inputs: [],
            outputs: [],
        },
        {
            name: "claimAvailableEsdt",
            mutability: "mutable",
            inputs: [],
            outputs: [],
        },
        {
            name: "getCurrentFunds",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "BigUint",
                },
            ],
        },
        {
            name: "request",
            mutability: "readonly",
            inputs: [
                {
                    name: "requester",
                    type: "Address",
                },
            ],
            outputs: [
                {
                    type: "RequestInfo",
                },
            ],
        },
        {
            name: "getTokenIdentifier",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "EgldOrEsdtTokenIdentifier",
                },
            ],
        },
        {
            name: "getRequester",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "Address",
                },
            ],
        },
        {
            name: "getClaimer",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "Address",
                },
            ],
        },
        {
            name: "getRequestTotalAmount",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "BigUint",
                },
            ],
        },
        {
            name: "getEpochPeriod",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "u64",
                },
            ],
        },
        {
            name: "getTokenRemainingSupply",
            mutability: "readonly",
            inputs: [],
            outputs: [
                {
                    type: "BigUint",
                },
            ],
        },
    ],
    events: [],
    hasCallback: false,
    types: {
        RequestInfo: {
            type: "struct",
            fields: [
                {
                    name: "request_address",
                    type: "Address",
                },
                {
                    name: "request_epoch",
                    type: "u64",
                },
                {
                    name: "request_claims",
                    type: "u8",
                },
                {
                    name: "request_status",
                    type: "bool",
                },
            ],
        },
    },
};
