import { useState } from "react";

export default function useFindUser() {
    const [signature, setSignature] = useState(null);
    const [reloadFetch, setReload] = useState(false);

    return {
        signature,
        setSignature,
        reloadFetch,
        setReload,
    };
}
