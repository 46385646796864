import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useField } from "formik";
import { Button, Form } from "react-bootstrap";
import PhotoCrop from "../PhotoCrop";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  flexDirection: "column",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "inline-flex",
  width: "auto",
  maxWidth: "100%",
  maxHeight: "100px",
  // Needed cast due to type issues
  objectFit: "contain" as "contain",
  height: "100%",
};

const FormikFileInput = (props: {
  name: string;
  accept: string;
  maxFiles: string;
  label: string;
  capture?: "user" | "environment";
}) => {
  const [inputKey, setInputKey] = useState(new Date().toISOString());
  const [, meta, helpers] = useField(props.name);
  const [queue, setQueue] = useState<File[]>();
  const { value } = meta;

  const maxFiles = useMemo(() => parseInt(props.maxFiles), [props.maxFiles]);

  const addImage = (blob: Blob, originalFile: File) => {
    const file = new File([blob], originalFile.name, {
      type: "image/jpeg", // We convert in cropperjs
    });
    const upFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    if (!value || value.length === maxFiles) {
      helpers.setValue([upFile]);
    } else {
      helpers.setValue([...value, upFile]);
    }
    const newQueue = queue || [];
    newQueue.shift();
    setQueue(newQueue as any);
  };

  const dismissImage = () => {
    const newQueue = queue || [];
    newQueue.shift();
    setInputKey(new Date().toISOString());
    setQueue(newQueue as any);
  };

  const onDrop = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    const acceptedFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const origFile = files.item(i);
      if (origFile) {
        acceptedFiles.push(origFile);
      }
    }

    setQueue(acceptedFiles);
  }, []);

  const deletePhoto = (file: File) => {
    helpers.setValue(value.filter((f: File) => f !== file));
    setInputKey(new Date().toISOString());
  };

  const thumbs = value
    ? value.map(
        (file: (File & { preview: string }) | string, index: number) => {
          if (typeof file === "string") {
            return (
              <div style={thumb as any} key={`uploaded-${index}`}>
                <div style={thumbInner as any}>
                  <img
                    src={file}
                    style={img}
                    alt={"Preview for uploaded file " + index}
                  />
                </div>
              </div>
            );
          }
          return (
            <div style={thumb as any} key={file.name}>
              <div style={thumbInner as any}>
                <img
                  src={file.preview}
                  style={img}
                  className="mb-1"
                  alt={"Preview for " + file.name}
                />
                <Button onClick={() => deletePhoto(file)}>Delete image</Button>
              </div>
            </div>
          );
        }
      )
    : [];

  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        key={inputKey}
        accept="image/*"
        capture={props.capture}
        type="file"
        onChange={onDrop}
      />
      <aside style={thumbsContainer as any}>{thumbs}</aside>

      <div>
        {queue && queue[0] && (
          <PhotoCrop
            key={queue[0].name + "-" + queue[0].size}
            photo={queue[0]}
            addImage={addImage}
            dismiss={dismissImage}
          />
        )}
      </div>
      {meta.touched && meta.error && (
        <p style={{ fontSize: "80%", color: "#dc3545" }}>{meta.error}</p>
      )}
    </Form.Group>
  );
};

export default FormikFileInput;
